<template>
  <main class="px-10">
    <div class="flex items-center justify-end mb-6">
      <TamButton @click="showDialog = true" color="black" label="Invite team member" />
    </div>
    <div class="relative overflow-x-auto sm:rounded-lg border">
      <table class="w-full text-sm text-left text-gray-500">
        <thead class="text-xs text-gray-700 uppercase bg-light-background">
          <tr>
            <th scope="col" class="px-6 py-3" v-for="item in tableHead" :key="item">{{ item }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in teamMembers?.members" :key="item.uuid" class="bg-white border-b hover:bg-gray-50">
            <td scope="row" class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">{{ item.name }}</td>

            <td class="px-6 py-4">{{ item.email }}</td>
            <td class="px-6 py-4 text-brand-black font-bold">
              {{ item.role }}
            </td>
            <td class="px-6 py-4 flex items-center">
              <button
                v-tooltip.top="'Update'"
                class="w-[20px] h-[20px] cursor-pointer mr-2"
                @click.prevent="
                  actionType = 'update';
                  prepareMemberUpdate(item);
                "
              >
                <img src="@/assets/icons/pencilline.svg" alt="" />
              </button>
              <span class="cursor-pointer" @click.prevent="removeUser(item.uuid)">
                <Trash />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="mt-10" id="invite">
      <div class="mb-6">
        <p class="text-2xl font-normal font-cooper">Invites</p>
        <p class="text-opacity-70 text-brand-black">Invited team members will receive an email. Invites expire after seven (7) days.</p>
      </div>
      <div>
        <div v-if="!hasPendinInvites">
          <Empty title="There are no invites available." text="You can invte team member's by using the invite button above." />
        </div>
        <div class="relative overflow-x-auto sm:rounded-lg border" v-else>
          <table class="w-full text-sm text-left text-gray-500">
            <thead class="text-xs text-gray-700 uppercase bg-light-background">
              <tr>
                <th scope="col" class="px-6 py-3" v-for="item in inviteTableHead" :key="item">{{ item }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in teamMembers?.invites" :key="item.uuid" class="bg-white border-b hover:bg-gray-50">
                <td scope="row" class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">{{ item.name }}</td>

                <td class="px-6 py-4">{{ item.email }}</td>
                <td class="px-6 py-4 text-right text-brand-success">
                  <div class="cursor-pointer" @click="resend(item.email)">
                    <span v-if="!teamLoaders[item.email].loading">Resend</span> <loading :is-loading="teamLoaders[item.email].loading" />
                  </div>
                </td>
                <td class="px-6 py-4 text-right text-brand-danger"><div class="cursor-pointer" @click="revoke(item.uuid)">Revoke invite</div></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- <TamModal v-show="showDialog" size="xl">
      <template #header>
        <div class="flex justify-between items-center">
          <p class="text-xl font-medium">Invite Team Member</p>
          <TamButton @click="showDialog = false" :icon="Close" />
        </div>
      </template>
      <template #body>
        <div id="modal-body" class="gap-y-2">
          <div class="mb-4 flex flex-col lg:grid gap-x-4 grid-cols-2">
            <div class="pt-4 flex flex-col">
              <label for="email-address-icon" class="block mb-2 text-sm font-medium text-[#090E11]/70">First name</label>

              <input
                v-model="details.first_name"
                type="text"
                id="email-address-icon"
                class="bg-[#F2F2F2] border-0 text-[#090E11] text-sm rounded-lg focus:ring-brand-button block w-full p-3"
              />
            </div>
            <div class="pt-4 flex flex-col">
              <label for="email-address-icon" class="block mb-2 text-sm font-medium text-[#090E11]/70">Last name</label>

              <input
                v-model="details.last_name"
                type="text"
                id="email-address-icon"
                class="bg-[#F2F2F2] border-0 text-[#090E11] text-sm rounded-lg focus:ring-brand-button block w-full p-3"
              />
            </div>
            <div class="pt-4 flex flex-col">
              <label for="email-address-icon" class="block mb-2 text-sm font-medium text-[#090E11]/70">Work email address</label>

              <input
                v-model="details.email"
                type="email"
                id="email-address-icon"
                class="bg-[#F2F2F2] border-0 text-[#090E11] text-sm rounded-lg focus:ring-brand-button focus:border-0 block w-full p-3"
              />
            </div>
            <div class="pt-4 flex flex-col">
              <label for="email-address-icon" class="block mb-2 text-sm font-medium text-[#090E11]/70">Role At Company</label>

              <select
                class="bg-[#F2F2F2] border-0 text-[#090E11] text-sm rounded-lg focus:ring-brand-button block w-full p-3"
                name="role_at_company"
                id="role_at_company"
                v-model="details.role_at_company"
              >
                <option selected disabled>Please Select</option>
                <option v-for="item in enums?.company_roles" :key="item.key" :value="item.key">{{ item.value }}</option>
              </select>
            </div>
          </div>
          <div class="mb-4 flex flex-col w-full">
            <label for="role" class="block mb-2 text-sm font-medium text-[#090E11]/70">Permission</label>

            <select
              class="bg-[#F2F2F2] border-0 text-[#090E11] text-sm rounded-lg focus:ring-brand-button block w-full p-3"
              name="role"
              id="role"
              v-model="details.role"
            >
              <option selected disabled>Please Select</option>
              <option v-for="item in enums?.employer_roles" :key="item.key" :value="item.key">{{ item.value }}</option>
            </select>
          </div>
        </div>
      </template>
      <template #footer>
        <tam-button class="w-full" :loading="loading" @click="invite" type="submit" color="info" label="Send Invite" />
      </template>
    </TamModal> -->
    <add-member-dialog
      v-show="showDialog"
      :action-type="actionType"
      :details="details"
      :company-roles="enums?.company_roles"
      :roles="enums?.employer_roles"
      @close="showDialog = false"
      @confirm="invite"
      @update="updateMember"
    />
  </main>

  <loading-overlay v-if="loading" />
</template>

<script setup>
import TamTable from '@/components/TamTable.vue';
import TamButton from '@/components/TamButton.vue';
import AddMemberDialog from '@/components/users/AddMember.vue';
import TamModal from '@/components/TamModal.vue';
import Empty from '../components/Empty.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/20/solid';
import More from '@/assets/icons/more.svg?inline';
import { ref, onMounted, computed } from 'vue';
import { Close, Trash } from '@/icons.js';
import { useStore } from 'vuex';
import { errorMessage, alert } from '@/utils/helper';
import { useToast } from 'vue-toastification';

const store = useStore();
const toast = useToast();

const teamMembers = ref({
  members: [],
  invites: [],
});
const showDialog = ref(false);
const loading = ref(false);
const actionType = ref('add');
const tableHead = ref(['TEAM MEMBER', 'EMAIL ADDRESS', 'ROLE', 'ACTIONS']);
const inviteTableHead = ref(['FULL NAME', 'EMAIL ADDRESS', '', '']);
const memberToUpdateId = ref(null);
const details = ref({
  first_name: '',
  last_name: '',
  email: '',
  role: '',
  role_at_company: '',
});
const enums = computed(() => store.getters['global/getEnumDetails']);
const teamLoaders = ref({});
const hasPendinInvites = computed(() => teamMembers.value.invites?.length > 0);

async function revoke(id) {
  try {
    const { message } = await store.dispatch('auth/revokeTeamMember', id);
    alert(message, 'success');
    await store.dispatch('auth/getTeamMembers', { forceReload: true });
    teamMembers.value = store.getters['auth/teamMembers'];
  } catch (error) {
    if (error.response && error.response.status === 422) {
    }
    alert(errorMessage(error), 'error');
  }
}

const setTeamLoadingState = (email) => {
  teamLoaders.value[email] = { loading: false };
};

const resend = async (email) => {
  try {
    if (teamLoaders.value[email].loading === true) return;
    teamLoaders.value[email].loading = true;
    const { message } = await store.dispatch('auth/resendTeamInvite', { email });
    await store.dispatch('auth/getTeamMembers', { forceReload: true });
    alert(message, 'success');
  } catch (error) {
    if (error.response && error.response.status === 422) {
    }
    alert(errorMessage(error), 'error');
  } finally {
    teamLoaders.value[email].loading = false;
  }
};

async function invite() {
  try {
    loading.value = true;
    const { message } = await store.dispatch('auth/inviteTeamMember', details.value);
    alert(message, 'success');
    showDialog.value = false;
    await store.dispatch('auth/getTeamMembers', { forceReload: true });
    teamMembers.value = store.getters['auth/teamMembers'];
    teamMembers.value.invites?.map((member) => setTeamLoadingState(member.email));
  } catch (error) {
    if (error.response && error.response.status === 422) {
    }
    alert(errorMessage(error), 'error');
  } finally {
    loading.value = false;
  }
}

async function removeUser(id) {
  try {
    const { message } = await store.dispatch('auth/removeTeamMember', id);
    alert(message, 'success');
    await store.dispatch('auth/getTeamMembers', { forceReload: true });
    teamMembers.value = store.getters['auth/teamMembers'];
    5;
  } catch (error) {
    if (error.response && error.response.status === 422) {
    }
    alert(errorMessage(error), 'error');
  }
}
const prepareMemberUpdate = (member) => {
  const { uuid, name, ...rest } = member;
  let nameList = name.split(' ');
  let last_name = nameList.slice(1).join(' ');
  memberToUpdateId.value = uuid;
  details.value = { ...rest, first_name: nameList[0], last_name };
  showDialog.value = true;
};

const updateMember = async () => {
  try {
    loading.value = true;
    const { first_name, last_name, ...rest } = details.value;
    const { message } = await store.dispatch('auth/updateTeamMember', {
      id: memberToUpdateId.value,
      details: { ...rest, name: `${first_name} ${last_name}` },
    });

    showDialog.value = false;
    memberToUpdateId.value = null;
    alert(message, 'success');
  } catch (error) {
    alert(errorMessage(error) || 'Something went wrong (Please check internet connection)', 'error', 5000);
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  try {
    if (!Array.isArray(store.getters['global/getEmployerRoles'])) {
      await store.dispatch('global/getEnums');
    }
    await store.dispatch('auth/getTeamMembers', { forceReload: true });
    teamMembers.value = store.getters['auth/teamMembers'];
    teamMembers.value.invites?.map((member) => setTeamLoadingState(member.email));
  } catch (error) {
    alert(errorMessage(error), 'error');
  }
});
</script>
