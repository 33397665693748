import { setAuthHeaders } from '@/utils/axios';
import AuthService from '../../services/auth.service';
// import { useToast } from 'vue-toastification';
import { checkStatus } from '@/utils/helper';

// const toast = useToast();
// const user = JSON.parse(localStorage.getItem('user'));
// const initialState = user ? { status: { loggedIn: true }, user } : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: {
    token: null,
    userDetails: {},
    teamMembers: {
      members: [],
      invites: [],
    },
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload;
      if (payload === null) {
        localStorage.removeItem('token');
      } else {
        localStorage.setItem('token', payload);
      }
    },
    registerSuccess(state, payload) {
      state.userDetails = payload;
    },
    loginSuccess(state, payload) {
      state.userDetails = payload;
    },
    logout(state) {
      state.userDetails = {};
      localStorage.removeItem('vuex');
      localStorage.clear();
    },
    teams(state, payload) {
      state.teamMembers = payload;
    },
    updateUserProfile(state, payload) {
      state.userDetails = { ...state.userDetails, ...payload };
    },
  },
  actions: {
    async login({ commit }, credential) {
      const { data } = await AuthService.login(credential);
      const { user, authorisation } = data.data;
      commit('loginSuccess', user);
      commit('setToken', authorisation.token);
      return { user, message: data.message };
    },
    async register({ commit }, credential) {
      const { data } = await AuthService.register(credential);
      const { user, authorisation } = data.data;
      commit('registerSuccess', user);
      commit('setToken', authorisation.token);
      return { user, message: data.message };
    },
    async logout({ commit }) {
      commit('setToken', null);
      commit('logout');
    },
    async requestOtp({ commit }, payload) {
      const res = await AuthService.requestOtp(payload);
      return res;
    },
    async verifyOtp({ commit }, credential) {
      const res = await AuthService.verifyOtp(credential);
      return res;
    },
    async forgetPasswordRequestOtp({ commit }, credential) {
      const { data } = await AuthService.forgetPasswordRequestOtp(credential);

      return data;
    },
    async forgetPasswordVerifyOtp({ commit }, credential) {
      const { data } = await AuthService.forgetPasswordVerifyOtp(credential);
      return data;
    },
    async forgetPasswordReset({ commit }, credential) {
      const res = await AuthService.forgetPasswordReset(credential);

      return res;
    },
    async resetPassword({ commit }, credential) {
      const res = await AuthService.resetPassword(credential);

      return res.data;
    },
    async getUser({ commit, state }, credential) {
      if (credential.forceReload) {
        const res = await AuthService.getUser();

        commit('loginSuccess', res.data.data);
      }
      return state.userDetails;
    },
    async getTeamMembers({ commit, state }, credential) {
      if (credential.forceReload) {
        const { data } = await AuthService.getTeamMembers();
        commit('teams', data.data);
      }
      return state.teamMembers;
    },
    async updateTeamMember({ commit }, credential) {
      const res = await AuthService.updateTeamMember(credential);

      return res.data;
    },
    async inviteTeamMember({ commit }, credential) {
      const res = await AuthService.inviteTeamMember(credential);

      return res.data;
    },
    async revokeTeamMember({ commit }, credential) {
      const res = await AuthService.revokeTeamMember(credential);

      return res.data;
    },
    async resendTeamInvite({ commit }, credential) {
      const { data } = await AuthService.resendTeamInvite(credential);
      return data;
    },
    async checkInvite({ commit }, email) {
      const { data } = await AuthService.verifyTeamInvite(email);
      return data;
    },
    async createInvitePassword({ commit }, credential) {
      const { data } = await AuthService.teamInvitePassword(credential);
      return data;
    },
    async removeTeamMember({ commit }, credential) {
      const { data } = await AuthService.removeTeamMember(credential);
      return data;
    },
    async updateUserProfile({ commit }, credential) {
      const { data } = await AuthService.updateUserProfile(credential);
      return data;
    },
    async updateEmployeeProfile({ commit }, credential) {
      const { data } = await AuthService.updateEmployeeProfile(credential);
      return data;
    },
    async updateUserAvatar({ commit }, credential) {
      const { data } = await AuthService.updateUserAvatar(credential);
      return data;
    },
    async updateEmployerAvatar({ commit }, credential) {
      const { data } = await AuthService.updateEmployerAvatar(credential);
      return data;
    },
    async updateCompanyBanner({ commit }, credential) {
      const { data } = await AuthService.updateCompanyBanner(credential);
      return data;
    },
    async updateUserResume({ commit }, credential) {
      const res = await AuthService.updateUserResume(credential);

      return res.data;
    },
    async onboardProfile({ commit }, credential) {
      const res = await AuthService.onboardProfile(credential);

      return res;
    },
    async onboardPreference({ commit }, credential) {
      const res = await AuthService.onboardPreference(credential);

      return res;
    },
    async onboardResume({ commit }, credential) {
      const res = await AuthService.onboardResume(credential);

      return res;
    },
  },

  getters: {
    userDetails: ({ userDetails }) => userDetails,
    accessToken: ({ token }) => token,
    teamMembers: ({ teamMembers }) => teamMembers,
  },
};
