<template>
  <main>
    <back />

    <div class="flex items-center justify-between mb-7 mt-8">
      <div>
        <h1 class="capitalize text-[18px] font-bold">{{ job?.role_title }}</h1>
        <div class="flex items-center mt-2">
          <span class="capitalize inline-block text-light-gray text-[13px]">{{ job?.work_type?.split('_').join(' ') }}</span>
          <img src="../../assets/icons/dot.svg" class="mx-2 w-[3.5px] h-[3.5px] inline-block" alt="" />
          <span class="capitalize inline-block text-light-gray text-[13px]">{{ getCountry(job?.country) }}</span>
          <img src="../../assets/icons/chevron-right.svg" alt="" class="mx-2" />
          <span class="capitalize inline-block text-brand-black text-[13px]">{{ job?.job_title_name }}</span>
        </div>
      </div>

      <div>
        <Menu as="div" class="relative inline-block text-left">
          <div>
            <MenuButton
              class="inline-flex w-full justify-center items-center space-x-3 rounded-lg px-7 py-3 text-sm font-medium text-brand-black border border-brand-black"
            >
              <span>Move To</span>
              <img src="@/assets/icons/chevron-down.svg" alt="" />
            </MenuButton>
          </div>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="absolute z-10 origin-top right-0 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none min-w-[185px]"
            >
              <div class="p-3">
                <MenuItem v-for="item in stages" :key="item.value" :value="item.value">
                  <a
                    href="#"
                    @click.prevent="
                      selectedStatus = item.value;
                      showStatusDialog = true;
                    "
                    class="p-3 py-2 text-brand-black block text-[14px] hover:bg-light-yellow rounded-lg capitalize"
                    >{{ item.key }}</a
                  >
                </MenuItem>
                <!-- <MenuItem>
                  <a href="#" class="p-3 py-2 text-brand-black block text-[14px] hover:bg-light-yellow rounded-lg">Background Check</a>
                </MenuItem>
                <MenuItem>
                  <a href="#" class="p-3 py-2 text-brand-black block text-[14px] hover:bg-light-yellow rounded-lg">Video Screening</a>
                </MenuItem>
                <MenuItem>
                  <a href="#" class="p-3 py-2 text-brand-black block text-[14px] hover:bg-light-yellow rounded-lg">Portfolio</a>
                </MenuItem>
                <MenuItem>
                  <a href="#" class="p-3 py-2 text-brand-black block text-[14px] hover:bg-light-yellow rounded-lg">Skill Set</a>
                </MenuItem> -->
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </div>

    <div class="p-6 rounded-lg bg-white mb-10">
      <div class="flex items-center mb-8">
        <img :src="application?.candidate?.avatar" alt="image" class="w-[56px] h-[56px] rounded-full mr-4" />
        <div>
          <h2 class="font-manrope font-bold leading-[160%]">{{ application?.candidate?.name }}</h2>
          <p class="text-light-gray-200 text-[14px] leading-[160%] font-manrope">{{ application?.candidate?.role_title }}</p>
        </div>
      </div>

      <div class="grid md:grid-cols-2">
        <div>
          <div class="flex space-x-2 mb-5">
            <p class="w-[150px] text-[14px] text-light-gray">Application Status</p>
            <p class="text-[14px] text-brand-black capitalize">{{ formatString(application?.status) }}</p>
          </div>
          <div class="flex space-x-2 mb-2">
            <p class="w-[150px] text-[14px] text-light-gray">Date Applied</p>
            <p class="text-[14px] text-brand-black">{{ moment(application?.created_at).format('D MMM, YYYY') }}</p>
          </div>
        </div>
        <div>
          <div class="flex space-x-2 mb-5">
            <p class="w-[150px] text-[14px] text-light-gray">Job Role</p>
            <p class="text-[14px] text-brand-black">{{ application?.job?.role_title }}</p>
          </div>
          <div class="flex space-x-2 mb-2">
            <p class="w-[150px] text-[14px] text-light-gray">Job Date</p>
            <p class="text-[14px] text-brand-black">{{ moment(application?.job?.created_at).format('D MMM, YYYY') }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="grid md:grid-cols-2 space-x-10 mb-16">
      <div>
        <ul class="flex items-center border-b border-light-grey mb-7">
          <li class="mr-2">
            <a
              href="#"
              @click.prevent="tab = 0"
              aria-current="page"
              :class="[
                'inline-block p-4 text-[15px]',
                tab === 0 ? 'border-b-2 border-brand-black text-brand-black font-semibold' : 'text-light-gray',
              ]"
              >Evaluation</a
            >
          </li>
          <li class="mr-2">
            <a
              href="#"
              @click.prevent="tab = 1"
              :class="[
                'inline-block p-4 text-[15px]',
                tab === 1 ? 'border-b-2 border-brand-black text-brand-black font-semibold' : 'text-light-gray',
              ]"
              >Notes</a
            >
          </li>
          <li class="mr-2">
            <a
              href="#"
              @click.prevent="tab = 2"
              :class="[
                'inline-block p-4 text-[15px]',
                tab === 2 ? 'border-b-2 border-brand-black text-brand-black font-semibold' : 'text-light-gray',
              ]"
              >Links</a
            >
          </li>
          <!-- <li class="mr-2">
            <a
              href="#"
              @click.prevent="tab = 3"
              :class="[
                'inline-block p-4 text-[15px]',
                tab === 3 ? 'border-b-2 border-brand-black text-brand-black font-semibold' : 'text-light-gray',
              ]"
              >Activity</a
            >
          </li> -->
        </ul>

        <evaluation v-if="tab == 0" :avatar="application?.job?.postedBy?.avatar_url" :application="application" :rating="rating" />
        <notes
          v-else-if="tab == 1"
          :avatar="application?.job?.postedBy?.avatar_url"
          :application="application"
          :prev-note="note"
          @confirm="getApplication"
        />
        <links v-else-if="tab == 2" />
        <div v-else>
          <h2>Activity</h2>
        </div>
      </div>

      <div class="relative h-[600px] rounded-lg overflow-hidden">
        <iframe :src="resumeUrl" id="resumeFrame" frameborder="0" class="absolute block top-0 left-0 right-0 bottom-0 w-[100%] h-[700px]"></iframe>
      </div>
    </div>
  </main>

  <Update-status-dialog
    :is-active="showStatusDialog"
    :status="selectedStatus"
    :application="application"
    :stages="stages"
    @close="showStatusDialog = false"
    @confirm="
      showStatusDialog = false;
      getApplication();
    "
  />

  <loading-overlay v-if="loading" />
</template>

<script setup>
import { onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { errorMessage, alert, getCountry, formatString } from '@/utils/helper';
import { useRouter, useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';
import moment from 'moment';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import Evaluation from '@/components/applications/Evaluation.vue';
import Notes from '@/components/applications/Notes.vue';
import Links from '@/components/applications/Links.vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import UpdateStatusDialog from '@/components/applications/UpdateStatusDialog.vue';
import Back from '@/components/BackButton.vue';

const store = useStore();
const router = useRouter();
const route = useRoute();
const toast = useToast();
const loading = ref(false);
const showStatusDialog = ref(false);
const selectedStatus = ref(false);
const application = ref({});
const rating = ref({ no: 0, comment: '' });
const note = ref('');
const tab = ref(0);
const kanban = ref({
  stages: ['pending', 'reviewed', 'hold', 'internal_review', 'interviewed', 'hired', 'rejected'],
});
const stages = ref([]);

const job = computed(() => {
  return application.value?.job || {};
});

const resumeUrl = computed(() => {
  const url = application.value?.candidate?.resume;
  if (!url) return 'N/A';

  const paths = url?.split('/');
  if (!paths) {
    alert('no resume to display', 'info');
    return;
  }
  const key = paths[paths.length - 1];
  const href = `/file/${key}`;

  return application.value?.resume_url || href;
});

const getApplication = async () => {
  loading.value = true;
  try {
    const data = route.params;
    if (!data.jobId) {
      toast.error('Application ID not found', {
        timeout: 3000,
        hideProgressBar: true,
      });
      return router.go(-1);
    }

    application.value = await store.dispatch('global/getTalentJobApplication', data);
    rating.value = application?.value?.rating ? application?.value?.rating : { no: 0, comment: '' };
    note.value = application?.value?.note;
  } catch (error) {
  } finally {
    loading.value = false;
  }
};

const getAppStages = async () => {
  loading.value = true;
  try {
    const result = await store.dispatch('global/getApplicationStatuses');
    stages.value = result.filter((item) => !item.value.includes('client'));
  } catch (error) {
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  await getApplication();
  await getAppStages();
  document.querySelector('#resumeFrame').style.height = '900px';
});
</script>
