<template>
  <Dialog v-show="isActive" class="backdrop-blur-sm" extend-class=" md:max-w-lg">
    <div class="my-6 bg-white rounded- w-full">
      <div class="flex justify-between px-6">
        <div class="w-full">
          <div class="flex justify-between items-start">
            <div class="mb-6">
              <h1 class="text-[24px] font-semibold leading-[130%] text-brand-black mb-2">
                {{ actionType == 'update' ? 'Edit Team Member' : 'Add Team Member' }}
              </h1>
              <div class="flex space-x-3 items-center">
                <img src="../../assets/icons/infoline.svg" alt="" />
                <small class="text-light-gray">You are inviting someone to join the team</small>
              </div>
            </div>

            <img src="../../assets/icons/closeline.svg" class="cursor-pointer" alt="" @click="close" />
          </div>

          <div class="pt-4 mb-6">
            <div class="w-full mb-3 flex items-center space-x-4">
              <div class="mb-3 w-1/2">
                <label class="inline-block text-[14px] text-brand-black mb-2">First Name <is-required /></label>
                <input
                  v-model="details.first_name"
                  type="text"
                  class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px]"
                  placeholder="E.g John"
                />
              </div>

              <div class="mb-3 w-1/2">
                <label class="inline-block text-[14px] text-brand-black mb-2">Last Name <is-required /></label>
                <input
                  v-model="details.last_name"
                  type="text"
                  class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px]"
                  placeholder="E.g Doe"
                />
              </div>
            </div>

            <div class="w-full mb-3 flex items-center space-x-4">
              <div class="mb-3 w-1/2">
                <label class="inline-block text-[14px] text-brand-black mb-2">Email Address <is-required /></label>
                <input
                  v-model="details.email"
                  type="email"
                  class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px]"
                  placeholder="Type an email address..."
                />
              </div>

              <div class="mb-3 w-1/2">
                <label class="inline-block text-[14px] text-brand-black mb-2">Choose Role at Company <is-required /></label>
                <select
                  v-model="details.role_at_company"
                  class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px]"
                  required
                >
                  <option value="">Choose Role</option>
                  <option :value="role.key" v-for="role in companyRoles" :key="role.key">{{ role.value }}</option>
                </select>
              </div>
            </div>

            <div class="w-full mb-3 flex items-center space-x-4">
              <div class="mb-3 w-full">
                <label class="inline-block text-[14px] text-brand-black mb-2">Choose Permission <is-required /></label>
                <select
                  v-model="details.role"
                  class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px]"
                  required
                >
                  <option value="">Choose Permission</option>
                  <option :value="role.key" v-for="role in roles" :key="role.key">{{ role.value }}</option>
                </select>
              </div>
            </div>
          </div>

          <div class="pt-2 flex space-x-6 items-center">
            <button type="button" @click.prevent="close" class="bg-white p-4 py-3 border border-brand-black rounded-lg w-1/2">Cancel</button>
            <button @click="confirm" class="bg-brand-black text-white p-4 py-3 rounded-lg w-1/2">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import Dialog from '@/components/Dialog.vue';
import SuccessSvg from '@/assets/icons/success.svg';

const props = defineProps({
  isActive: { type: Boolean, default: false },
  actionType: { type: Boolean, default: 'add' },
  details: { type: Object },
  companyRoles: { type: Array },
  roles: { type: Array },
});

const emit = defineEmits(['confirm', 'update', 'close']);

const confirm = () => {
  if (props.actionType == 'update') {
    emit('update');
  } else {
    emit('confirm');
  }
};

const close = () => {
  emit('close');
};
</script>
