import { setAuthHeaders } from '@/utils/axios';
import GlobalService from '../../services/global.service';
import { useToast } from 'vue-toastification';
import { checkStatus, alert } from '@/utils/helper';
import * as localforage from 'localforage';

const toast = useToast();

export const global = {
  namespaced: true,
  state: {
    EnumDetails: {},
    paymentGateways: {},
    Countries: [],
    states: [],
    companyCategory: [],
    jobTitles: [],
    jobCategories: [],
    plans: [],
    Jobs: {},
    payments: null,
    Talents: null,
    TalentPool: null,
    applications: null,
    jobApplications: null,
    talentJobApplication: null,
    resumeUrls: [],
    appSettings: null,
    userForm: {},
    redirectUrl: null,
  },
  mutations: {
    setForm(state, payload) {
      if (payload === null) {
        state.userForm = {};
        localforage.removeItem('user_form');
      } else if (payload.key) {
        state.userForm[payload.key] = payload.value;
        localforage.setItem('user_form', JSON.stringify(state.userForm));
      } else {
        state.userForm = { ...state.userForm, ...payload };
        localforage.setItem('user_form', JSON.stringify(state.userForm));
      }
    },
    setResumeUrl(state, payload) {
      const idx = state.resumeUrls.findIndex((resume) => resume.key === payload.key);
      if (idx == -1) {
        state.resumeUrls.push(payload);
      } else {
        state.resumeUrls[idx] = payload;
      }
      localforage.setItem('resume_urls', JSON.stringify(state.resumeUrls));
    },
    Countries(state, payload) {
      state.Countries = payload;
    },
    setStates(state, payload) {
      state.states = payload;
    },
    Enum(state, payload) {
      state.EnumDetails = payload;
    },
    setCompanyCategory(state, payload) {
      state.companyCategory = payload;
    },
    setJobCategories(state, payload) {
      state.jobCategories = payload;
    },
    setJobTitles(state, payload) {
      state.jobTitles = payload;
    },
    setApplications(state, payload) {
      state.applications = payload;
    },
    setJobApplications(state, payload) {
      state.jobApplications = payload;
    },
    setTalentJobApplication(state, payload) {
      state.talentJobApplication = payload;
    },
    setJobs(state, payload) {
      localforage.setItem('Jobs', payload);
      state.Jobs = payload;
    },
    setPaymentHistory(state, payload) {
      state.payments = payload;
    },
    setTalents(state, payload) {
      state.Talents = payload;
    },
    setTalentPool(state, payload) {
      state.setTalentPool = payload;
    },
    setPlans(state, payload) {
      state.plans = payload;
    },
    setPaymentGateways(state, payload) {
      state.paymentGateways = payload;
    },
    setAppSettings(state, payload) {
      state.appSettings = payload;
    },
    setRedirectUrl(state, url) {
      state.redirectUrl = url;
    },
  },
  getters: {
    getJob:
      ({ Jobs }) =>
      async (id) => {
        try {
          if (Array.isArray(Jobs.data) && Jobs.data?.length > 0) {
            process.env.NODE_ENV === 'development' ? console.log(Jobs.data) : '';
            return Jobs.data?.filter((job) => job.fid == id)[0] || null;
          }
          const jobs = await localforage.getItem('Jobs');
          process.env.NODE_ENV === 'development' ? console.log(jobs.data) : '';
          return jobs.data?.filter((job) => job.fid == id)[0] || null;
        } catch (error) {
          // alert(error.message, 'error');
          return null;
        }
      },
    getJobWithUuid:
      ({ Jobs }) =>
      async (id) => {
        try {
          if (Array.isArray(Jobs.data) && Jobs.data?.length > 0) {
            process.env.NODE_ENV === 'development' ? console.log(Jobs.data) : '';
            return Jobs.data?.filter((job) => job.uuid == id)[0] || null;
          }
          const jobs = await localforage.getItem('Jobs');
          process.env.NODE_ENV === 'development' ? console.log(jobs.data) : '';
          return jobs.data?.filter((job) => job.uuid == id)[0] || null;
        } catch (error) {
          // alert(error.message, 'error');
          return null;
        }
      },
    getForm: async ({ userForm }) => {
      try {
        const user_form = JSON.parse(await localforage.getItem('user_form'));
        process.env.NODE_ENV === 'development' ? console.log(user_form) : '';
        return user_form || userForm;
      } catch (error) {
        alert(error.message, 'error');
        return null;
      }
    },
    getFormPart:
      ({ userForm }) =>
      async (key) => {
        try {
          const user_form = JSON.parse(await localforage.getItem('user_form'));
          return user_form ? user_form[key] : userForm[key];
        } catch (error) {
          alert(error.message, 'error');
          return null;
        }
      },
    getResumeUrl:
      ({ resumeUrls }) =>
      async (file_name) => {
        try {
          if (Array.isArray(resumeUrls) && resumeUrls.length > 0) {
            return resumeUrls.filter((url) => url.key === file_name)[0]?.url || null;
          }
          const resume_urls = JSON.parse(await localforage.getItem('resume_urls'));
          return resume_urls.filter((url) => url.key === file_name)[0]?.url || null;
        } catch (error) {
          alert(error.message, 'error');
          return null;
        }
      },
    getAllJobs(state) {
      return state.Jobs?.data || [];
    },
    getPayments(state) {
      return state.payments?.data || [];
    },
    getPlans(state) {
      return state.plans || [];
    },
    getJobsPayload(state) {
      return state.Jobs;
    },
    getApplicationsPayload(state) {
      return state.applications;
    },
    getJobApplicationsPayload(state) {
      return state.jobApplications;
    },

    getAllTalents(state) {
      return state.Talents?.data || [];
    },
    getAllTalentPool(state) {
      return state.TalentPool?.data || [];
    },
    getTalentsPayload(state) {
      return state.Talents;
    },
    getPaymentProcessors(state) {
      return state.paymentGateways;
    },
    getSettings(state) {
      return state.appSettings;
    },
    getCountries: ({ Countries }) => Countries,
    getCountry:
      ({ Countries }) =>
      (id) =>
        Countries.filter((country) => country.id == id)[0],
    getStates: ({ states }) => states,
    getState:
      ({ states }) =>
      (id) =>
        states.filter((state) => state.id == id)[0],
    getEnumDetails: ({ EnumDetails }) => EnumDetails,
    getEmployerRoles: ({ EnumDetails }) => EnumDetails?.employer_roles,
    getCompanyRoles: ({ EnumDetails }) => EnumDetails?.company_roles,
    getJobCategories: ({ jobCategories }) => jobCategories,
    getJobCategory:
      ({ jobCategories }) =>
      (key) =>
        jobCategories.filter((jobCategory) => jobCategory.key == key)[0],
    getCompanyCategory: ({ companyCategory }) => companyCategory,
    getRedirectUrl: ({ redirectUrl }) => redirectUrl,
  },

  actions: {
    // Enums
    async getEnums({ commit }) {
      const res = await GlobalService.getEnum();
      commit('Enum', res.data.data);
      return res.data.data;
    },
    async getCountries({ commit }) {
      const { data } = await GlobalService.getCountries();
      commit('Countries', data.data);
      return data.data;
    },
    async getStates({ commit }, credential) {
      const res = await GlobalService.getStates(credential);
      commit('setStates', res.data.data);
      return res.data.data;
    },
    async getCompanyCategory({ commit }) {
      const res = await GlobalService.getCompanyCategory();
      commit('setCompanyCategory', res.data.data);
      return res.data.data;
    },
    async getJobCategories({ commit }) {
      const res = await GlobalService.getJobCategories();
      commit('setJobCategories', res.data.data);
      return res.data.data;
    },
    async getJobTitles({ commit }) {
      const res = await GlobalService.getJobTitles();
      commit('setJobTitles', res.data.data);
      return res.data.data;
    },
    async updateApplicationStatus({ commit }, credential) {
      const res = await GlobalService.updateSingleJobStatus(credential);
      return res.data;
    },
    // Talents
    async getAllTalents({ commit }, credential) {
      const res = await GlobalService.getAllTalents(credential);
      commit('setTalents', res.data);
      return res.data;
    },
    async getSingleTalent({ commit }, credential) {
      const res = await GlobalService.getSingleTalent(credential);
      return res.data.data;
    },
    async shareTalentProfile({ commit }, credential) {
      const res = await GlobalService.shareTalentProfile(credential);
      return res.data;
    },
    async emailTalent({ commit }, credential) {
      const res = await GlobalService.emailTalent(credential);
      return res.data;
    },
    // Talent Pool
    async getAllTalentPool({ commit }, credential) {
      const res = await GlobalService.getAllTalentPool(credential);
      commit('setTalentPool', res.data);
      return res.data;
    },
    async getFeatures({ commit, state }, credential) {
      const res = await GlobalService.getFeatures();
      return res.data;
    },
    // Subscription
    async getSubscriptionPlans({ commit }, credential) {
      const res = await GlobalService.getSubscriptionPlans();
      commit('setPlans', res.data.data);
      return res.data;
    },
    async getPaymentProcessors({ commit }) {
      const { data } = await GlobalService.getPaymentProcessors();
      commit('setPaymentGateways', data);
      return data;
    },
    async getSubscriptionPayments({ commit }) {
      const { data } = await GlobalService.getSubscriptionPayments();
      commit('setPaymentHistory', data);
      return data;
    },
    async makePayment({ commit }, credential) {
      const { data } = await GlobalService.makePayment(credential);
      return data;
    },
    async updatePayment({ commit }, credential) {
      const { data } = await GlobalService.updatePayment(credential);
      return data;
    },
    // Jobs
    async getAllJobs({ commit }, credential) {
      const res = await GlobalService.getAllJobs(credential);
      commit('setJobs', res.data);
      return res.data;
    },
    async getSingleJob({ commit }, credential) {
      const res = await GlobalService.getSingleJob(credential);
      return res.data.data;
    },
    async getSingleJobApplications({ commit }, credential) {
      const res = await GlobalService.getSingleJobApplications(credential);
      commit('setJobApplications', res.data);
      return res.data.data;
    },
    async getTalentJobApplication({ commit }, credential) {
      const res = await GlobalService.getTalentJobApplication(credential);
      commit('setTalentJobApplication', res.data);
      return res.data.data;
    },
    async createJob({ commit }, credential) {
      const res = await GlobalService.createJob(credential);
      return res.data.data;
    },
    async updateSingleJob({ commit }, credential) {
      const res = await GlobalService.updateSingleJob(credential);
      return res.data.data;
    },
    async updateSingleJobStatus({ commit }, credential) {
      const res = await GlobalService.updateSingleJobStatus(credential);
      if (!checkStatus(res.data.status)) throw Error(res.data.err_message);
      return res.data;
    },
    async deleteSingleJob({ commit }, credential) {
      const res = await GlobalService.deleteSingleJob(credential);
      if (!checkStatus(res.data.status)) throw Error(res.data.err_message);
      return res.data;
    },
    async getJobOverviewCount({ commit }, credential) {
      const res = await GlobalService.getJobOverviewCount();
      return res.data;
    },
    async inviteToJob({ commit, state }, credential) {
      const res = await GlobalService.inviteToJob(credential);
      // commit('setJobApplications', res.data);
      return res.data;
    },
    // async getJobApplicationsOverview({ commit }) {
    //   const { data } = await GlobalService.getJobApplicationsOverview();
    //   return data;
    // },

    // Track Applications
    async getAllApplication({ commit, state }, credential) {
      if (credential.forceReload) {
        const res = await GlobalService.getAllApplication(credential.params);
        commit('setApplications', res.data);
      }

      return state.applications?.data;
    },
    async updateApplication({ commit }, credential) {
      const res = await GlobalService.updateApplication(credential);
      return res.data;
    },
    async getApplicationStatusCount({ commit }, credential) {
      const res = await GlobalService.getApplicationStatusCount();
      return res.data;
    },
    async getApplicationOverviewCount({ commit }, credential) {
      const res = await GlobalService.getApplicationOverviewCount();
      return res.data;
    },
    async hireApplicant({ commit }, credential) {
      const res = await GlobalService.hireApplicant(credential);
      return res.data;
    },
    async scheduleInterview({ commit }, credential) {
      const res = await GlobalService.scheduleInterview(credential);
      return res.data;
    },
    async rejectApplicant({ commit }, credential) {
      const res = await GlobalService.rejectApplicant(credential);
      return res.data;
    },
    async getSettings({ commit }) {
      const res = await GlobalService.getSettings();
      commit('setAppSettings', res.data.data);
      return res.data.data;
    },
    async exportJobOpenings({ commit }) {
      const res = await GlobalService.exportJobOpenings();
      return res.data;
    },
    async addApplicationEvaluation({ commit, state }, credential) {
      const res = await GlobalService.addApplicationEvaluation(credential);
      return res.data;
    },
    async jobInvite({ commit }, credential) {
      const res = await GlobalService.jobInvite(credential);
      return res.data.data;
    },
    async jobInviteManual({ commit }, credential) {
      const res = await GlobalService.jobInviteManual(credential);
      return res.data;
    },
    async jobInviteCSV({ commit }, credential) {
      const res = await GlobalService.jobInviteCSV(credential);
      return res.data;
    },
    async getConversionRate({ commit }, credential) {
      const res = await GlobalService.getConversionRate();
      return res.data;
    },
    async uploadFileUrl({ commit }, credential) {
      const res = await GlobalService.uploadFileUrl(credential);
      return res.data;
    },
    async getInfoFromResumeParser({ commit }, credential) {
      const res = await GlobalService.getInfoFromResumeParser(credential);
      return res.data;
    },
    async getApplicationStatuses({ commit }) {
      const { data } = await GlobalService.getApplicationStatuses();
      return data;
    },
  },
};
