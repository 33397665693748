import DashboardLayout from '../layouts/DashboardLayout.vue';
import { UserMiddleware } from '@/utils/middleware';

export default [
  {
    path: '/',
    redirect: { name: 'EmployerDasboard' },
    meta: {
      menu: 'employer',
      requiresAuth: true,
    },
  },
  {
    path: '/register',
    name: 'EmployerRegister',
    props: true,
    component: () => import(/* webpackChunkName: "EmployerRegister" */ '@/views/auth/RegisterNew.vue'),
    meta: {
      menu: 'employer',
      middleware: [UserMiddleware],
    },
  },
  {
    path: '/register-password',
    name: 'EmployerRegisterPassword',
    component: () => import(/* webpackChunkName: "EmployerRegisterPassword" */ '@/views/auth/RegisterPassword.vue'),
    meta: {
      menu: 'employer',
    },
  },
  {
    path: '/register-verify',
    name: 'EmployerRegisterVerify',
    component: () => import(/* webpackChunkName: "EmployerRegisterVerify" */ '@/views/auth/RegisterVerifyNew.vue'),
    meta: {
      menu: 'employer',
      requiresAuth: true,
    },
  },
  {
    path: '/profile-setup',
    name: 'ProfileInformation',
    component: () => import(/* webpackChunkName: "ProfileInformation" */ '@/views/auth/ProfileInfoNew.vue'),
    meta: {
      menu: 'employer',
      // requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'EmployerLogin',
    component: () => import(/* webpackChunkName: "EmployerLogin" */ '@/views/auth/LoginNew.vue'),
    meta: {
      menu: 'employer',
      middleware: [UserMiddleware],
    },
  },
  {
    path: '/reset-password',
    name: 'EmployerResetPasswordEmail',
    component: () => import(/* webpackChunkName: "EmployerResetPasswordEmail" */ '@/views/auth/ResetPasswordEmail.vue'),
    meta: {
      menu: 'employer',
    },
  },
  {
    path: '/reset-password-otp',
    name: 'EmployerResetPasswordOtp',
    component: () => import(/* webpackChunkName: "EmployerResetPasswordOtp" */ '@/views/auth/ResetPasswordOtp.vue'),
    meta: {
      menu: 'employer',
    },
  },
  {
    path: '/reset-password-form',
    name: 'EmployerResetPasswordForm',
    component: () => import(/* webpackChunkName: "EmployerResetPasswordForm" */ '@/views/auth/ResetPasswordForm.vue'),
    meta: {
      menu: 'employer',
    },
  },
  {
    path: '/invite/:uuid',
    name: 'EmployerInviteComplete',
    component: () => import(/* webpackChunkName: "EmployerInviteComplete" */ '@/views/auth/EmployerInviteComplete.vue'),
    meta: {
      menu: 'employer',
      requiresAuth: false,
    },
  },
  // EMPLOYERS DASHBOARD
  {
    path: '/new-job',
    name: 'CreateJobsDescription',
    component: () => import(/* webpackChunkName: "CreateJob" */ '@/views/NewJob.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/edit-job/:id/:uuid',
    name: 'EditJobsDescription',
    component: () => import(/* webpackChunkName: "EditJob" */ '@/views/EditJob.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/file/:uri',
    name: 'TalentResume',
    component: () => import(/* webpackChunkName: "TalentResume" */ '@/views/TalentResume.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  //New design implementation
  // {
  //   path: '/login-new',
  //   name: 'EmployerLoginNew',
  //   component: () => import(/* webpackChunkName: "EmployerLogin" */ '@/views/auth/LoginNew.vue'),
  //   meta: {
  //     menu: 'employer',
  //     //middleware: [UserMiddleware],
  //   },
  // },
  // {
  //   path: '/register-new',
  //   name: 'EmployerRegisterNew',
  //   props: true,
  //   component: () => import(/* webpackChunkName: "EmployerRegister" */ '@/views/auth/RegisterNew.vue'),
  //   meta: {
  //     menu: 'employer',
  //     //middleware: [UserMiddleware],
  //   },
  // },
  // {
  //   path: '/register-verify-new',
  //   name: 'EmployerRegisterVerifyNew',
  //   component: () => import(/* webpackChunkName: "EmployerRegisterVerify" */ '@/views/auth/RegisterVerifyNew.vue'),
  //   meta: {
  //     menu: 'employer',
  //     //requiresAuth: true,
  //   },
  // },
  // {
  //   path: '/profile-setup-new',
  //   name: 'ProfileSetup',
  //   component: () => import(/* webpackChunkName: "ProfileInformationNew" */ '@/views/auth/ProfileInfoNew.vue'),
  //   meta: {
  //     menu: 'employer',
  //     //requiresAuth: true,
  //   },
  // },

  {
    path: '/dashboardLayout',
    name: 'EmployerDashboardLayout',
    component: DashboardLayout,
    children: [
      {
        meta: {
          title: 'Dashboard',
          text: 'See how your team is doing',
          menu: 'employer',
          requiresAuth: true,
        },
        path: '/dashboard',
        name: 'EmployerDasboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "EmployerDahboard" */ '@/views/Home.vue'),
      },
      {
        meta: {
          title: 'Talent',
          text: 'We have curated a list of talented individuals for your opening.',
          menu: 'employer',
          requiresAuth: true,
        },
        path: '/talentpool',
        name: 'EmployerTalentPool',
        component: () => import(/* webpackChunkName: "EmployerTalent" */ '@/views/Talent.vue'),
      },
      {
        meta: {
          title: '',
          text: '',
          menu: 'employer',
          requiresAuth: true,
        },
        path: '/talentpool/:id',
        name: 'EmployerTalentPoolDetails',
        component: () => import(/* webpackChunkName: "EmployerTalent" */ '@/views/TalentDetails.vue'),
      },
      {
        meta: {
          title: 'Jobs',
          text: 'The openings you post will show here',
          menu: 'employer',
          requiresAuth: true,
        },
        path: '/jobs',
        name: 'EmployerJobs',
        component: () => import(/* webpackChunkName: "EmployerJobs" */ '@/views/Jobs.vue'),
      },
      {
        meta: {
          title: '',
          text: '',
          menu: 'employer',
          requiresAuth: true,
        },
        path: '/jobs/:id/:job_title',
        name: 'EmployerJobDetails',
        component: () => import(/* webpackChunkName: "EmployerTalent" */ '@/views/JobDetails.vue'),
      },
      {
        meta: {
          title: 'Subscription Plans',
          text: '',
          menu: 'employer',
          requiresAuth: true,
        },
        path: '/subscriptions',
        name: 'SubscriptionPlans',
        component: () => import(/* webpackChunkName: "EmployerJobs" */ '@/views/Subscriptions.vue'),
      },
      {
        meta: {
          title: 'Subscription Plans',
          text: '',
          requiresAuth: true,
        },
        path: '/subscription-plan/:id',
        name: 'SubscriptionPlanDetail',
        component: () => import(/* webpackChunkName: "EditJob" */ '@/views/SubscriptionPlan.vue'),
      },
      {
        meta: {
          title: 'My Application',
          text: 'Properly organize your candidates from application to hired.',
          menu: 'employer',
          requiresAuth: true,
        },
        path: '/tracking',
        name: 'EmployerTracking',
        component: () => import(/* webpackChunkName: "EmployerTracking" */ '@/views/Track.vue'),
      },
      {
        meta: {
          title: 'My Applications',
          // text: 'Properly organize your candidates from application to hired.',
          menu: 'employer',
          requiresAuth: true,
        },
        path: '/tracking/singleApplication/:id',
        name: 'EmployerTrackingSingle',
        component: () => import(/* webpackChunkName: "EmployerTracking" */ '@/components/applications/SingleApplication.vue'),
      },
      {
        meta: {
          title: 'My aplication',
          // text: 'Properly organize your candidates from application to hired.',
          menu: 'employer',
          requiresAuth: true,
        },
        path: '/tracking/previewApplication/:jobId/application/:applicationId',
        name: 'EmployerTrackingPreview',
        component: () => import(/* webpackChunkName: "EmployerTracking" */ '@/components/applications/PreviewApplication.vue'),
      },
      {
        meta: {
          title: 'Conversations',
          text: 'Send emails with our templates and keep record of responses.',
          menu: 'employer',
          requiresAuth: true,
        },
        path: '/conversations',
        name: 'EmployerConversations',
        component: () => import(/* webpackChunkName: "EmployerConversation" */ '@/views/Conversation.vue'),
      },
      {
        meta: {
          title: 'Team',
          text: 'Here you see every member of your recruiting team.',
          menu: 'employer',
          requiresAuth: true,
        },
        path: '/team',
        name: 'EmployerTeam',
        component: () => import(/* webpackChunkName: "EmployerEmployees" */ '@/views/Teams.vue'),
      },
      {
        meta: {
          title: 'Settings',
          text: 'Here you edit profile details.',
          menu: 'employer',
          requiresAuth: true,
        },
        path: '/settings',
        name: 'EmployerSetting',
        component: () => import(/* webpackChunkName: "EmployerSettings" */ '@/views/Settings.vue'),
      },
    ],
  },
];
