<template>
  <nav
    class="top-0 left-0 right-0 fixed flex bg-white border-b border-[#E9EAEC] h-24 z-30 w-screen transition-position xl:pl-60 lg:w-auto lg:items-stretch"
    :class="[lightBorderStyle, { 'ml-60 lg:ml-0': isAsideMobileExpanded }]"
  >
    <div class="flex w-full max-w-[1900px] mx-auto p-0 lg:px-9 lg:pt-6 lg:pb-2">
      <div class="flex-1 items-stretch flex">
        <nav-bar-item type="flex lg:hidden" @click.prevent="menuToggleMobile">
          <icon :path="menuToggleMobileIcon" size="24" />
        </nav-bar-item>
        <nav-bar-item type="hidden lg:flex xl:hidden" @click.prevent="menuOpenLg">
          <icon :path="mdiMenu" size="24" />
        </nav-bar-item>

        <nav-bar-item class="hidden lg:flex">
          <!-- <nav-bar-search /> -->
          <div class="">
            <h5 class="text-[18px] leading-[160%]">{{ currentRouteName }}</h5>
            <!-- <p class="text-opacity-70 text-brand-black">{{ currentRouteSubText }}</p> -->
          </div>
        </nav-bar-item>
      </div>

      <div class="flex-none items-stretch flex lg:hidden">
        <nav-bar-item @click.prevent="menuNavBarToggle">
          <icon :path="menuNavBarToggleIcon" size="24" />
        </nav-bar-item>
      </div>

      <div
        class="absolute w-screen top-20 left-0 bg-white shadow lg:w-auto lg:items-stretch lg:flex lg:grow lg:static lg:border-b-0 lg:overflow-visible lg:shadow-none"
        :class="[isMenuNavBarActive ? 'block' : 'hidden']"
      >
        <div class="max-h-screen-menu overflow-y-auto lg:overflow-visible lg:flex lg:items-stretch lg:justify-end lg:ml-auto">
          <nav-bar-item is-desktop-icon-only @click.prevent="toggleLightDark">
            <nav-bar-item-label is-desktop-icon-only>
              <img src="@/assets/icons/cog.svg" />
            </nav-bar-item-label>
          </nav-bar-item>

          <nav-bar-item class="mr-3" is-desktop-icon-only @click.prevent="toggleLightDark">
            <nav-bar-item-label is-desktop-icon-only >
              <img src="@/assets/icons/notify.svg" />
            </nav-bar-item-label>
          </nav-bar-item>

          <div class="inline-flex items-center">
            <user-avatar size="w-8 h-8" />
            <nav-bar-menu>
              <nav-bar-item-label :label="userName"> </nav-bar-item-label>

              <template #dropdown>
                <!-- <nav-bar-item to="/profile">
                <nav-bar-item-label :icon="mdiAccount" label="My Profile" />
              </nav-bar-item> -->
                <nav-bar-item>
                  <router-link to="/settings"><nav-bar-item-label :icon="mdiCogOutline" label="Settings" /></router-link>
                </nav-bar-item>
                <nav-bar-item>
                  <router-link to="/conversations"><nav-bar-item-label :icon="mdiEmail" label="Messages" /></router-link>
                </nav-bar-item>
                <divider nav-bar />
                <nav-bar-item @click="logout" class="text-brand-danger">
                  <nav-bar-item-label :icon="mdiLogout" label="Log Out" />
                </nav-bar-item>
              </template>
            </nav-bar-menu>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import {
  mdiForwardburger,
  mdiBackburger,
  mdiClose,
  mdiDotsVertical,
  mdiMenu,
  // mdiClockOutline,
  // mdiCloud,
  // mdiCrop,
  // mdiAccount,
  // mdiCogOutline,
  // mdiEmail,
  // mdiLogout,
  // mdiGithub,
  // mdiThemeLightDark
} from '@mdi/js';
import NavBarItem from '@/components/NavBarItem.vue';
import NavBarItemLabel from '@/components/NavBarItemLabel.vue';
import NavBarMenu from '@/components/partials/NavBarMenu.vue';
import notifyIcon from '@/assets/icons/notify.svg'
import UserAvatar from '@/components/UserAvatar.vue';
import Icon from '@/components/Icon.vue';

export default {
  components: {
    NavBarItem,
    NavBarItemLabel,
    NavBarMenu,
    Icon,
    UserAvatar,
    // NavBarSearch
  },
  props: ['menuType'],

  data() {
    return {
      isMenuNavBarActive: false,
      mdiMenu: mdiMenu,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.meta.title || '';
    },
    currentRouteSubText() {
      return this.$route.meta.text;
    },
    lightBorderStyle() {
      return this.$store.state.lightBorderStyle;
    },
    isNavBarVisible() {
      return !this.$store.state.isFullScreen;
    },
    isAsideMobileExpanded() {
      return this.$store.state.isAsideMobileExpanded;
    },
    userName() {
      return this.$store.state.auth.userDetails.name;
    },
    menuToggleMobileIcon() {
      return this.isAsideMobileExpanded.value ? mdiBackburger : mdiForwardburger;
    },
    menuNavBarToggleIcon() {
      return this.isMenuNavBarActive ? mdiClose : mdiDotsVertical;
    },
  },
  methods: {
    toggleLightDark() {
      this.$store.dispatch('darkMode');
    },
    menuToggleMobile() {
      this.$store.dispatch('asideMobileToggle');
    },
    menuNavBarToggle() {
      this.isMenuNavBarActive = !this.isMenuNavBarActive;
    },
    menuOpenLg() {
      this.$store.dispatch('asideLgToggle', true);
    },
    async logout() {
      await this.$store.dispatch('auth/logout');
      return this.$router.push('/login');
    },
  },
};
</script>
